/** @jsx jsx */
import React, { ReactElement, useEffect, useState, Fragment } from 'react';
import { Link } from '@sprinklr/shared-lib/components/link';
import { Box, jsx } from 'theme-ui';
import { checkForAndRemoveTrailingSlash } from '@sprinklr/shared-lib/utils/getPaginatedSlugs.js';
import { useResponsiveValue } from '@theme-ui/match-media';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import { removeNodeLocaleFromSlug } from '@sprinklr/shared-lib/utils/removeNodeLocaleFromSlug';
import useHasScrolled from '@sprinklr/shared-lib/templates/headerTemplate/useHasScrolled';
import { HeaderHeightListener } from '@sprinklr/shared-lib/context/HeaderHeightContext';

const HEADER_FULL_HEIGHT = 85;
const HEADER_COLLAPSED_OFFSET = 15;
const HEADER_COLLAPSED_HEIGHT = HEADER_FULL_HEIGHT - HEADER_COLLAPSED_OFFSET;

type Item = {
  link: string;
  text: string;
};

interface SubMenuProps {
  items: Item[];
}

const DesktopMenu = ({
  items,
  activeItem,
}: {
  items: Item[];
  activeItem: number;
}): ReactElement => {
  const hasScrolled = useHasScrolled(15);

  const desktopTop = hasScrolled ? HEADER_COLLAPSED_HEIGHT : HEADER_FULL_HEIGHT;

  return (
    <HeaderHeightListener>
      <Box
        sx={{
          backgroundColor: '#fff',
          top: [
            'var(--header-height,66px)',
            'var(--header-height,96px)',
            'var(--header-height,96px)',
            'var(--header-height,96px)',
            `var(--header-height,${desktopTop}px)`,
          ],
          display: ['none', 'none', 'flex', 'flex'],
          justifyContent: 'center',
          alignItems: 'center',
          borderTop: '1.5px solid #DFDFDF',
          borderBottom: '1.5px solid #DFDFDF',
          padding: '0 10px',
          position: 'sticky',
          zIndex: 29,
          transition: '.2s box-shadow, .2s top',
          ...(hasScrolled
            ? { boxShadow: '0px 8px 14px 0px rgba(0, 0, 0, 0.1)' }
            : undefined),
        }}
      >
        {items.map((item, index) => (
          <Link
            key={index}
            href={item.link}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: index === 0 ? '1.5px solid #DFDFDF' : undefined,
              borderRight: '1.5px solid #DFDFDF',
              paddingX: ['12px', '12px', '16px', '44px'],
              paddingY: '12px',
              cursor: 'pointer',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              color: '#000',
              position: 'relative',
              ':hover': {
                background: '#107EFF15',
              },
            }}
            linkSx={{
              textDecoration: 'none',
            }}
          >
            {item.text}
            {index === activeItem ? (
              <Box
                sx={{
                  height: '4px',
                  backgroundColor: '#1C6CFD',
                  width: '100%',
                  bottom: '-1.5px',
                  position: 'absolute',
                }}
              />
            ) : null}
          </Link>
        ))}
      </Box>
    </HeaderHeightListener>
  );
};

const StickySubMenu = ({ items }: SubMenuProps): ReactElement => {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const pathName = isWindowDefined()
    ? removeNodeLocaleFromSlug(window.location.pathname)
    : '';

  const isMobile = useResponsiveValue([true, true, false, false], {
    defaultIndex: 3,
  });

  useEffect(() => {
    const activeItemIndex = items?.findIndex(
      item =>
        checkForAndRemoveTrailingSlash(item.link) ===
        checkForAndRemoveTrailingSlash(pathName),
    );

    if (activeItemIndex >= 0 && activeItem !== activeItemIndex) {
      setActiveItem(activeItemIndex);
    }
  }, [items, pathName]);

  const renderMenu = () => {
    if (isMobile) {
      return null;
    }
    return <DesktopMenu items={items} activeItem={activeItem} />;
  };

  return <Fragment>{renderMenu()}</Fragment>;
};

export default StickySubMenu;
